<template>
<div class="course-analysis">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="course-analysis-cont">
    <div class="course-analysis-cont-top">
      <div class="tab">
        <template v-for="item in statusList" >
          <p :class="curTabId == item.id ? 'active' : ''" v-if="item.id == 1 || item.id == 4" :key="item.id" @click="changeTab(item.id)">{{item.name}}的班级({{item.val}})</p>
        </template>
      </div>
    </div>

    <div class="group-list" v-if="tableData.length">
      <div class="group-item" v-for="item in tableData" :key="item.id" >
        <div class="group-item-top">
          <p class="group-item-name">{{ item.name }}</p>
          <p class="group-item-time" v-if="item.start_time > 0">{{ util.timeFormatter(new Date(+item.start_time * 1000), 'yyyy.MM.dd') }} - {{ util.timeFormatter(new Date(+item.end_time * 1000), 'yyyy.MM.dd') }}</p>
          <p class="group-item-time" v-else>长期</p>
        </div>
        <div class="group-item-bot">
          <div>
            <p>学生：{{ item.count }}人</p>
            <p>课程：{{item.course_count}}课</p>
            <p>完课率：{{ item.finish_rate }}%</p>
          </div>
          <el-button type="primary" size="small" @click="goDetail(item)">查看详情</el-button>
        </div>
      </div>

      <Pagin
          style="margin-top: 20px;text-align: end;"
          :pagination="pagination"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
      />
    </div>
    <div class="no-data" v-if="!loading && !tableData.length" style="padding: 42px 0;" >
      <img src="../../../assets/images/new_icon/no-data.png" width="255" >
      <p>暂无班级...</p>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Pagin from "@/components/myPagin";
export default {
  name: "trainstat",
  data(){
    return{
      util,
      statusList:[],
      curTabId:'1',
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      tableData:[],
      loading:true,
    }
  },
  components:{
    Pagin
  },
  created(){
    this.getForm();
    this.getClassList();
  },
  methods:{
    getForm(){
      let params = {
        with_group_count:'1',
        user_id:'-1'
      };
      this.api.user.groupForm(params).then((res)=>{
        this.statusList = [];
        for(let name in res.statuses){
          let arr = res.group_status_count_list.filter((item)=>{
            return item.status == name;
          })
          let obj = {
            name:res.statuses[name],
            id:name
          };
          if(arr.length){
            obj.val = arr[0].count;
          }else{
            obj.val = 0;
          }
          this.statusList.push(obj)
        }
      })
    },
    changeTab(id){
      this.curTabId = id;
      this.pagination.page = 1;
      this.getClassList();
    },
    // 获取班级列表数据
    getClassList() {
      const { pagination } = this;
      this.loading = true;
      this.api.user.groupList({
        ...pagination,
        status:this.curTabId,
        with_course_finish_rate:'1',
        user_id:'-1'
      }).then(res => {
        this.loading = false;
        this.tableData = res.list || [];
        pagination.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      });
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getClassList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      pagination.page = 1;
      this.getClassList();
    },
    goDetail(data){
      this.$router.push({
        path:'/trainingcamp/groupAnalysis',
        query:{
          id:data.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.course-analysis{
  padding: 20px;
  .course-analysis-cont{
    padding: 20px;
    background-color: #fff;
    .course-analysis-cont-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;
      .tab{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p{
          margin-right: 30px;
          padding-bottom: 10px;
          height: 100%;
          font-size: 16px;
          color: #666666;
          cursor: pointer;
          &.active{
            font-weight: bold;
            color: #4A79FF;
            border-bottom: 4px solid #5782FF;
          }
        }
      }
    }
    .group-list{
      .group-item{
        margin-bottom: 20px;
        padding: 20px;
        background-color: #F6F7FA !important;
        border-radius: 6px;
        .group-item-top{
          display: flex;
          justify-content: space-between;
          align-items: center;

          .group-item-name{
            font-weight: bold;
            font-size: 18px;
            color: #333333;
          }
          .group-item-time{
            color:#666666;
            font-size: 14px;
            font-weight: normal;
          }
        }
        .group-item-bot{
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color:#666666;
            font-size: 14px;
            >p{
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .no-data{
    padding: 100px 0;
    font-size: 14px;
    text-align: center;
    background-color: #FFFFFF;
  }
}
</style>
